import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Contactform from "../../components/contactform"



const ContactPage = () => {
   return(
   <Layout>
        <SEO title="Contact Us" />
        <br/>
        <h1 style={{textAlign: "center"}}>We value your feedback. Reach out to us through the form below.</h1>
        <Contactform/>
   </Layout>
      );
};

export default ContactPage
