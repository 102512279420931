import React from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from "axios";


const Contactform = () => {
    const [serverState, setServerState] = React.useState({
        submitting: false,
        status: null
      });
      const handleServerResponse = (ok, msg, form) => {
        setServerState({
          submitting: false,
          status: { ok, msg }
        });
        if (ok) {
          form.reset();
        }
      };
      const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
          method: "post",
          url: "https://getform.io/f/2385bd8d-e1db-4320-a546-55b77024cc88",
          data: new FormData(form)
        })
          .then(r => {
            handleServerResponse(true, "Thanks!", form);
          })
          .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
          });
      };
      return(  
        <Form onSubmit={handleOnSubmit}>
            <Form.Label>Name</Form.Label>
            
            <Form.Control type="text" name="name" placeholder="Enter name"/>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter email" />
    <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text>
  </Form.Group>
    <Form.Label>Message</Form.Label>
    <Form.Control type="text" as='textarea' rows='5' name="message" placeholder="Enter message" />
  <br/>
  <Button variant="primary" type="submit">
    Submit
  </Button>
  {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}</p>
            )}
</Form>
      );
};

export default Contactform
